import { Box, Button, Divider, FormHelperText, Grid, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Alert from "@material-ui/lab/Alert";
import { Form as Formik, FormikProps } from "formik";
import { uniq } from "lodash-es";
import { ChangeEvent, useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import avatarPlaceholder from "assets/icons/profile/avatar.svg";
import editAvatarImg from "assets/icons/profile/image-camera.svg";
import coverPlaceholder from "assets/images/profile/cover-placeholder.svg";

import { TConfigsCityIId } from "data/configs/types";
import { EUserProfilePictureKind, EUserStatus } from "data/users/types";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { BO_LOCALHOST_PREVIEW_TABS, paths } from "routing/paths";

import useCity from "store/hooks/useCity";
import useLanguages from "store/hooks/useLanguages";
import store from "store/index";
import { selectUserStatus } from "store/selectors/user";

import fillRoute from "utils/routes";

import ChipStringArray from "components/experiences/ChipStringArray";
import VerifiedStatusBox from "components/experiences/VerifiedStatusBox";
import Input from "components/formik/Input";

import Breadcrumbs from "ui/Breadcrumbs";
import ImageEditor, { EDialogTypes } from "ui/ImageEditDialog";
import LocalhostStatusLabel from "ui/statuses/LocalhostStatusLabel";

import { StyledLink } from "styles/common";

import { FormValues } from "../../index";
import StringTags from "../StringTags";
import {
	AboutMeContent,
	AboutMeText,
	AvatarProfile,
	CreateOutlinedIcon,
	DarkBlueBtn,
	DetailsContent,
	EditAvatarBtn,
	EditBackgroundPhotoBtn,
	EditBtn,
	InfoIcon,
	MainTitleSection,
	MottoContent,
	MottoText,
	NameSurname,
	OutlinedBlueBtn,
	PhotoContent,
	Profession,
	RatingText,
	SaveBtnsContent,
	SaveDesc,
	StarIcon,
	StatusContent,
	// ThumbIcon,
	StyledAutocomplete,
	TitleContent,
} from "./shared.styled";

const ProfileView = ({
	isSubmitting,
	values,
	errors,
	resetForm,
	handleSubmit,
	setFieldValue,
	submitForm,
	validateForm,
	publicProfile,
	travelerType,
	staffEditMode,
}: FormikProps<FormValues> & { publicProfile: boolean; travelerType: boolean; staffEditMode?: boolean }) => {
	const { t, withValuesAsString } = useTranslation();

	const { uid } = useParams<{ uid?: string }>();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isLargeScreen = useMediaQuery(theme.breakpoints.down("md"));

	const { citiesSorted } = useCity();
	const { languagesSelectSorted } = useLanguages();

	const { addError, clear } = useNotification();

	const [isEditable, setIsEditable] = useState(false);
	const [openAvatarDialog, setOpenAvatarDialog] = useState(false);
	const [openCoverDialog, setOpenCoverDialog] = useState(false);
	const [editName, setEditName] = useState(false);
	const [editLastName, setEditLastName] = useState(false);
	const [editMotto, setEditMotto] = useState(false);
	const [editDescription, setEditDescription] = useState(false);
	const [editOccupation, setEditOccupation] = useState(false);
	const [editLanguages, setEditLanguages] = useState(false);
	const [editSkills, setEditSkills] = useState(false);
	const [editCities, setEditCities] = useState(false);

	const disableActionButtons =
		values.status === EUserStatus.UNDER_REVIEW || values.status === EUserStatus.PUBLISHED__UNDER_REVIEW;

	const closeEditableVersion = useCallback(() => {
		setEditName(false);
		setEditLastName(false);
		setEditMotto(false);
		setEditDescription(false);
		setEditOccupation(false);
		setEditLanguages(false);
		setEditSkills(false);
		setEditCities(false);
		setIsEditable(false);
	}, []);

	const cancelForm = useCallback(() => {
		resetForm();
		closeEditableVersion();
	}, [resetForm, closeEditableVersion]);

	const handleDialog = (kind: EUserProfilePictureKind) => () => {
		if (kind === EUserProfilePictureKind.profile) {
			setOpenAvatarDialog(prevState => !prevState);
		}

		if (kind === EUserProfilePictureKind.cover) {
			setOpenCoverDialog(prevState => !prevState);
		}
	};

	const saveAsDraftAction = useCallback(async () => {
		clear();

		const validationErrors = await validateForm();

		if (Object.keys(validationErrors).length) {
			addError(withValuesAsString("ERRORS.VALIDATION_EXTENDED", { errors: Object.keys(validationErrors).join(", ") }));

			try {
				document.getElementsByName(Object.keys(validationErrors)[0])[0].focus();

				return;
			} catch {
				return;
			}
		}

		await submitForm();

		const actionStatus = selectUserStatus(store.getState());

		if (actionStatus !== "error") {
			closeEditableVersion();
		}
		// eslint-disable-next-line
	}, [validateForm, submitForm, closeEditableVersion]);

	const saveForReviewAction = useCallback(async () => {
		await setFieldValue("sendToReviewIndicator_HELPER", true);

		await saveAsDraftAction();
	}, [saveAsDraftAction, setFieldValue]);

	const handleSaveDialog = (kind: EUserProfilePictureKind) => (imgBlob: Blob, imgBase64: string) => {
		let valuesBase64PathString: string = "pictures.";
		let valuesBlobPathString: string = "picturesBlobs_HELPER.";

		if (kind === EUserProfilePictureKind.profile) {
			valuesBase64PathString = valuesBase64PathString + EUserProfilePictureKind.profile;
			valuesBlobPathString = valuesBlobPathString + EUserProfilePictureKind.profile;
		}

		if (kind === EUserProfilePictureKind.cover) {
			valuesBase64PathString = valuesBase64PathString + EUserProfilePictureKind.cover;
			valuesBlobPathString = valuesBlobPathString + EUserProfilePictureKind.cover;
		}

		setFieldValue(valuesBase64PathString, imgBase64);
		setFieldValue(valuesBlobPathString, imgBlob);
	};

	const handleCityAutocompleteChange = (event: ChangeEvent, value: TConfigsCityIId[]) => {
		const newSelectedCities = value.map(elem => elem.name);

		setFieldValue("cities", newSelectedCities.length ? uniq(newSelectedCities) : []);
	};

	return (
		<Formik onSubmit={handleSubmit}>
			{publicProfile && (
				<Helmet>
					{values.first_name && (
						<>
							<title>
								{t("SEO.PUBLIC_PROFILE.TITLE")} {values.first_name} | LocalBini
							</title>
							<meta property="og:title" content={`${t("SEO.PUBLIC_PROFILE.TITLE")} ${values.first_name} | LocalBini`} />
						</>
					)}
					{values.skills_and_interests?.length && (
						<meta name="keywords" content={values.skills_and_interests.join(", ")} />
					)}
				</Helmet>
			)}

			<>
				{openCoverDialog && (
					<ImageEditor
						initialImage={values?.pictures?.[EUserProfilePictureKind.cover] ?? undefined}
						showDialog
						handleClose={handleDialog(EUserProfilePictureKind.cover)}
						saveImage={handleSaveDialog(EUserProfilePictureKind.cover)}
						kind={EDialogTypes.cover}
					/>
				)}

				{openAvatarDialog && (
					<ImageEditor
						initialImage={values?.pictures?.[EUserProfilePictureKind.profile] ?? undefined}
						showDialog
						handleClose={handleDialog(EUserProfilePictureKind.profile)}
						saveImage={handleSaveDialog(EUserProfilePictureKind.profile)}
					/>
				)}

				<Box marginTop={5} marginBottom={5}>
					<Box marginBottom={5}>
						<Breadcrumbs
							lastBreadcrumbLabel={publicProfile ? t("PROFILE.LOCALHOST_PROFILE") : t("TOPBAR.MY_PROFILE_LABEL")}
						/>
					</Box>

					{staffEditMode && (
						<Box marginBottom={5}>
							<Alert severity="warning">
								{t("PROFILE.STAFF_EDIT_MODE")}{" "}
								<strong>
									<StyledLink
										to={fillRoute(paths.BACKOFFICE_LOCALHOSTS_PREVIEW, {
											id: uid,
											page: BO_LOCALHOST_PREVIEW_TABS.LOCALHOST_INFO,
										})}
										target="_blank"
									>
										{uid}
									</StyledLink>
								</strong>
							</Alert>
						</Box>
					)}

					{(!publicProfile || staffEditMode) && !disableActionButtons && (
						<Box
							display="flex"
							alignItems={isSmallScreen ? "flex-end" : "center"}
							justifyContent={isEditable ? "space-between" : "flex-end"}
							width="100%"
							height="100%"
							paddingBottom={5}
							flexDirection={isSmallScreen ? "column-reverse" : "row"}
						>
							{isEditable && (
								<SaveBtnsContent>
									<Box display="flex" marginBottom={isSmallScreen ? 3 : undefined}>
										<InfoIcon />

										<SaveDesc>{t("PROFILE.SAVE_BTNS_DESCRIPTION")}</SaveDesc>
									</Box>

									<Box display="flex" alignItems="center" justifyContent="space-between">
										<DarkBlueBtn onClick={saveAsDraftAction} variant="contained" disabled={isSubmitting}>
											{t(!travelerType ? "PROFILE.SAVE_AS_DRAFT_BTN" : "COMMON.SAVE")}
										</DarkBlueBtn>

										{!travelerType && (
											<DarkBlueBtn onClick={saveForReviewAction} variant="contained" disabled={isSubmitting}>
												{t("PROFILE.SEND_TO_REVIEW_BTN")}
											</DarkBlueBtn>
										)}

										<OutlinedBlueBtn variant="outlined" onClick={cancelForm}>
											{t("COMMON.CANCEL")}
										</OutlinedBlueBtn>
									</Box>
								</SaveBtnsContent>
							)}

							<Button
								color="primary"
								disabled={isEditable}
								variant="contained"
								onClick={() => setIsEditable(!isEditable)}
							>
								{t("PROFILE.EDIT_PROFILE_BTN")}
							</Button>
						</Box>
					)}

					<Divider />

					{(!publicProfile || staffEditMode) && !travelerType && (
						<Box mt="10px">
							{/* @ts-ignore */}
							<LocalhostStatusLabel type={values.status} />
						</Box>
					)}
				</Box>

				<PhotoContent urlImg={values?.pictures?.[EUserProfilePictureKind.cover] || coverPlaceholder}>
					{isEditable && (
						<EditBackgroundPhotoBtn onClick={handleDialog(EUserProfilePictureKind.cover)}>
							<CreateOutlinedIcon />
						</EditBackgroundPhotoBtn>
					)}
				</PhotoContent>

				<Grid container spacing={10}>
					<Grid item xs={12} lg={4}>
						<Box marginTop={-60} width={!isLargeScreen ? "350px" : "100%"}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								flexDirection="column"
								position="relative"
							>
								<AvatarProfile
									title={`${values.first_name || "Avatar"} - profile picture`}
									alt={`${values.first_name || "Avatar"} - profile picture`}
									src={values?.pictures?.[EUserProfilePictureKind.profile] || avatarPlaceholder}
								/>

								{isEditable && (
									<EditAvatarBtn onClick={handleDialog(EUserProfilePictureKind.profile)}>
										<img alt="Add avatar profile" src={editAvatarImg} height="24px" width="24px" />
									</EditAvatarBtn>
								)}
							</Box>

							<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
								<Box
									display="flex"
									alignItems="center"
									justifyContent={isEditable ? "space-between" : "center"}
									width="100%"
								>
									{editName ? (
										<Input
											inputAndWrapperStyles={{ width: 300 }}
											name="first_name"
											type="text"
											readOnly={isSubmitting}
											value={values.first_name}
											onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("first_name", e.target.value)}
											onKeyDown={e => {
												if (e.key === "Enter") {
													e.preventDefault();
													setEditName(!editName);
												}
											}}
										/>
									) : (
										<>
											<NameSurname>{values.first_name || t("PROFILE.YOUR_NAME_PLACEHOLDER")}</NameSurname>

											<FormHelperText error>{errors?.first_name}</FormHelperText>
										</>
									)}

									{isEditable && (
										<EditBtn onClick={() => setEditName(!editName)}>
											<CreateOutlinedIcon />
										</EditBtn>
									)}
								</Box>
								<Box
									display="flex"
									alignItems="center"
									justifyContent={isEditable ? "space-between" : "center"}
									width="100%"
								>
									{editLastName ? (
										<Input
											inputAndWrapperStyles={{ width: 300 }}
											name="last_name"
											type="text"
											readOnly={isSubmitting}
											value={values.last_name}
											onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("last_name", e.target.value)}
											onKeyDown={e => {
												if (e.key === "Enter") {
													e.preventDefault();
													setEditLastName(!editLastName);
												}
											}}
										/>
									) : (
										<>
											<NameSurname>{values.last_name || t("PROFILE.YOUR_LAST_NAME_PLACEHOLDER")}</NameSurname>

											<FormHelperText error>{errors?.last_name}</FormHelperText>
										</>
									)}

									{isEditable && (
										<EditBtn onClick={() => setEditLastName(!editLastName)}>
											<CreateOutlinedIcon />
										</EditBtn>
									)}
								</Box>

								<Box
									display="flex"
									alignItems="center"
									justifyContent={isEditable ? "space-between" : "center"}
									width="100%"
								>
									{editOccupation ? (
										<Input
											inputAndWrapperStyles={{ width: 300 }}
											name="occupation"
											type="text"
											readOnly={isSubmitting}
											value={values.occupation}
											onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("occupation", e.target.value)}
											onKeyDown={e => {
												if (e.key === "Enter") {
													e.preventDefault();
													setEditOccupation(!editOccupation);
												}
											}}
											maxLength={100}
										/>
									) : (
										<>
											<Profession>{values.occupation || t("PROFILE.OCCUPATION_PLACEHOLDER")}</Profession>

											<FormHelperText error>{errors?.occupation}</FormHelperText>
										</>
									)}

									{isEditable && (
										<EditBtn onClick={() => setEditOccupation(!editOccupation)}>
											<CreateOutlinedIcon />
										</EditBtn>
									)}
								</Box>
							</Box>

							{values.is_verified && (
								<StatusContent>
									<VerifiedStatusBox currentStatus={t("PROFILE.VERIFIED_USER")} withoutMarginLeft />
								</StatusContent>
							)}

							{!travelerType && !!values.ratings?.count && (
								<>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<MainTitleSection>{t("PROFILE.RATINGS_TITLE")}</MainTitleSection>

										{/* <MainTitleSection>{t("PROFILE.CANCELATION_RATE_TITLE")}</MainTitleSection>*/}
									</Box>

									<Divider />

									<DetailsContent>
										<Box display="flex" alignItems="center" justifyContent="space-between">
											<StarIcon />

											<RatingText>
												<strong>{values.ratings.average}</strong> ({values.ratings.count})
											</RatingText>
										</Box>

										{/*<Box display="flex" alignItems="center" justifyContent="space-between">
                      <ThumbIcon />
                      <RatingText>
                         @todo:backend - get from store
                        <strong>1%</strong> (360)
                      </RatingText>
                    </Box>*/}
									</DetailsContent>
								</>
							)}

							<Box display="flex" alignItems="center" justifyContent="space-between">
								<MainTitleSection>{t("PROFILE.LANGUAGES_TITLE")}</MainTitleSection>

								{isEditable && (
									<EditBtn onClick={() => setEditLanguages(!editLanguages)}>
										<CreateOutlinedIcon />
									</EditBtn>
								)}
							</Box>

							<Divider />

							<DetailsContent>
								{!editLanguages ? (
									<ChipStringArray valuesArray={values?.languages || []} />
								) : (
									<StringTags
										onChange={v => setFieldValue("languages", [...v])}
										value={values?.languages || []}
										options={languagesSelectSorted.map(elem => elem.label)}
										translateDic={false}
									/>
								)}
							</DetailsContent>

							<Box display="flex" alignItems="center" justifyContent="space-between">
								<MainTitleSection>{t("PROFILE.SKILLS_INTERESTS_TITLE")}</MainTitleSection>

								{isEditable && (
									<EditBtn onClick={() => setEditSkills(!editSkills)}>
										<CreateOutlinedIcon />
									</EditBtn>
								)}
							</Box>

							<Divider />

							<DetailsContent>
								{!editSkills ? (
									<ChipStringArray valuesArray={values?.skills_and_interests || []} />
								) : (
									<StringTags
										add
										onChange={v => setFieldValue("skills_and_interests", [...v])}
										value={values?.skills_and_interests || []}
										field="SKILLS_INTERESTS"
										translateDic={false}
									/>
								)}
							</DetailsContent>

							{!travelerType && (
								<>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<MainTitleSection>{t("PROFILE.CITIES_TITLE")}</MainTitleSection>

										{isEditable && (
											<EditBtn onClick={() => setEditCities(!editCities)}>
												<CreateOutlinedIcon />
											</EditBtn>
										)}
									</Box>

									<Divider />

									<DetailsContent column={editCities}>
										{!editCities ? (
											<>
												<ChipStringArray valuesArray={values?.cities || []} />

												{errors?.cities && <FormHelperText error>{errors?.cities}</FormHelperText>}
											</>
										) : (
											<>
												<StyledAutocomplete
													popupIcon={<SearchIcon />}
													multiple
													filterSelectedOptions
													id="cities_list"
													options={citiesSorted}
													value={
														values.cities ? (values.cities.map(elem => ({ name: elem })) as TConfigsCityIId[]) : []
													}
													getOptionSelected={(option: { name: string }, selected: { name: string }) =>
														option.name === selected.name
													}
													onChange={handleCityAutocompleteChange}
													getOptionLabel={(city: TConfigsCityIId) => city.name}
													renderInput={params => (
														<TextField
															{...params}
															id="cities_list_input"
															label={t("ONBOARDING.CITIES.INPUT_LABEL")}
															placeholder={t("ONBOARDING.CITIES.INPUT_PLACEHOLDER")}
															variant="outlined"
															fullWidth
														/>
													)}
												/>

												{errors?.cities && <FormHelperText error>{errors?.cities}</FormHelperText>}
											</>
										)}
									</DetailsContent>
								</>
							)}
						</Box>
					</Grid>

					<Grid item xs={12} lg={8}>
						<TitleContent>
							<MainTitleSection>{t("PROFILE.MY_MOTTO_TITLE")}</MainTitleSection>

							{isEditable && (
								<EditBtn onClick={() => setEditMotto(!editMotto)} marginRight>
									<CreateOutlinedIcon />
								</EditBtn>
							)}
						</TitleContent>

						<Divider />

						<MottoContent>
							{editMotto ? (
								<Input
									inputAndWrapperStyles={{ width: "100%" }}
									name="motto"
									type="text"
									readOnly={isSubmitting}
									value={values.motto}
									onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("motto", e.target.value)}
									onKeyDown={e => {
										if (e.key === "Enter") {
											e.preventDefault();
											setEditMotto(!editMotto);
										}
									}}
									maxLength={300}
								/>
							) : (
								<MottoText>
									„{values.motto || t("PROFILE.YOUR_MOTTO_PLACEHOLDER")}”
									<FormHelperText error>{errors?.motto}</FormHelperText>
								</MottoText>
							)}
						</MottoContent>

						<AboutMeContent>
							<TitleContent>
								<MainTitleSection>{t("PROFILE.MY_DESCRIPTION_TITLE")}</MainTitleSection>
								{isEditable && (
									<EditBtn onClick={() => setEditDescription(!editDescription)}>
										<CreateOutlinedIcon />
									</EditBtn>
								)}
							</TitleContent>

							<Divider />

							{editDescription ? (
								<Box marginTop={5}>
									<Input
										inputAndWrapperStyles={{ width: "100%" }}
										name="description"
										label=""
										type="text"
										multiline
										readOnly={isSubmitting}
										value={values.description}
										onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("description", e.target.value)}
										onKeyDown={e => {
											if (e.key === "Enter") {
												e.preventDefault();
												setEditDescription(!editDescription);
											}
										}}
										maxLength={1000}
									/>
								</Box>
							) : (
								<AboutMeText>
									„{values.description || t("PROFILE.DESCRIPTION_PLACEHOLDER")}”
									<FormHelperText error>{errors?.description}</FormHelperText>
								</AboutMeText>
							)}
						</AboutMeContent>
					</Grid>
				</Grid>
			</>
		</Formik>
	);
};

export default ProfileView;
