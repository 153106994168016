import { IId } from "data/types";
import { EUserOnlineStatus } from "data/users/types";

import colors from "styles/colors";

export const excludedIIdFields: (keyof IId)[] = ["id", "modified_by", "created_by", "modified_on", "created_on"];

export const sessionExpiredQueryParam = "sessionExpired";
export const newPasswordRequiredQueryParam = "newPasswordRequired";

export const marketingIDParam = "mid";
export const accountTypeParam = "accountType";
export const languageParam = "lang";

export const localStorageKeys = {
	user: {
		hasEverLogged: { key: "__LOCALBINI__.user.hasEverLogged", defaultValue: "true" },
	},
	notifications: {
		showOnlyUnread: { key: "__LOCALBINI__.user.notifications.showOnlyUnread", defaultValue: "true" },
	},
	languages: {
		selectedLanguage: {
			key: "__LOCALBINI__.languages.selectedLanguage",
			defaultValue: "en",
		},
	},
};

export const promoCodeForMarketing = "LBMARKETINGONLYINTERNALUSE";

// tslint:disable-next-line:variable-name
export const TEMP_classifications_id_list_for_block_prices = [
	"csf-a1397c9d71fa4b1e8af041e02957a9b3",
	"csf-d70172812ec24c91a63ecee34e39610e",
	"csf-f4587289ca05486ab411d786d2a0c065",
	"csf-38dac2dd0b40461d82d004b05a2f1544",
	"csf-10ceb31fc1984540bfc420243082b739",
	"csf-c8ff6193cd32487099f9f8bdb68a1b5a",
	"csf-f7a74d8ff94744abae92451d7c0317d1",
];

export const maxLimitOfItemsForAPICalls = 100;

export const cookieNameForCookiesPopup = "localbiniCookiesAgreement";

export const localbiniProductionDomainLink = "https://localbini.com";

export const USER_ONLINE_STATUS_COLOR = {
	[EUserOnlineStatus.ACTIVE]: colors.accentGreen500,
	[EUserOnlineStatus.MID_ACTIVE]: colors.accentGreen200,
	[EUserOnlineStatus.LESS_ACTIVE]: colors.accentYellow200,
	[EUserOnlineStatus.NO_ACTIVE]: colors.accentRed500,
	[EUserOnlineStatus.UNDEFINED]: colors.accentGrey300,
};
