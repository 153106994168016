import { Avatar, Box, Button } from "@material-ui/core";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { EBookingStatus, ITickets } from "data/backoffice/bookings/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { DATE_FORMATS, minutesToHM, oneHourInMinutes, parseISOWrapper } from "utils/dates";
import fillRoute from "utils/routes";

import { CancelBookingButton } from "components/bookings/CancelBookingButton";
import DownloadTicketButton from "components/bookings/DownloadTicketButton";
import RateBookingButton from "components/bookings/RateBookingButton";

import Dialog from "ui/Dialog";
import LinkDownloadFileICS from "ui/LinkDownloadFileICS";
import LinkGoogleCalendar from "ui/LinkGoogleCalendar";
import LinkGoogleMaps from "ui/LinkGoogleMaps";

import colors from "styles/colors";
import { StyledExternalLink, StyledLink } from "styles/common";
import media from "styles/media";

import ElemClipboard from "../../backoffice/ElemClipboard";
import { Col, StyledDivider, Text, Title } from "../shared.styled";

const Wrapper = styled.div`
	display: flex;
	width: 100%;

	${media.tablet`
    flex-direction: column;
  `};
`;

const StyledTitle = styled(Title)`
	font-size: 14px;
`;

const Item = styled.div<{ withMarginTop?: boolean }>`
	${props => props.withMarginTop && "margin-top: 40px;"};
	width: 100%;
	display: flex;
	margin-bottom: 10px;
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

const DescWrapper = styled.div<{ maxHeight?: string; padding?: string }>`
	width: 100%;
	padding: ${props => props.padding || "44px 22px"};
	max-height: ${props => props.maxHeight || "500px"};
	overflow-y: auto;

	${media.tablet`
    max-height: initial;
  `};
`;

const AvatarWrapper = styled.div`
	width: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StyledAvatar = styled(Avatar)`
	margin-bottom: 5px;
	width: 54px;
	height: 54px;
`;

const ImgContent = styled.div<{ mainPhotoUrl: string }>`
	width: 100%;
	height: 100%;
	background-image: url(${props => props.mainPhotoUrl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const StyledDialog = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		margin: 0 auto;
		min-width: 850px;

		.MuiDialogContent-root {
			padding: 0;
		}

		.MuiDialogContent-root:first-child {
			padding-top: 0;
		}

		${media.tablet`
      min-width: 100%;
    `};
	}
`;

interface IBookingDetails {
	title: string;
	startDate?: string;
	startLocation: string;
	tickets?: ITickets;
	language: string;
	link?: string;
	firstNameLocalhost: string | null;
	phoneNumberLocalhost: string | null;
	avatarUrlLocalhost?: string;
	mainPhoto?: string;
	experienceTime?: number;
	experienceTimeZone?: string;
	comment?: string | null;
	status?: EBookingStatus;
	statusLabel?: ReactElement;
	cost?: string;
	localhostLink?: string | null;
	children?: ReactNode;
	wrapper?: {
		maxHeight?: string;
		padding?: string;
	};
	bookingId?: string;
}

export const BookingDetails = ({
	tickets,
	title,
	startDate,
	startLocation,
	cost,
	localhostLink,
	language,
	link,
	firstNameLocalhost,
	phoneNumberLocalhost,
	avatarUrlLocalhost,
	mainPhoto,
	experienceTime,
	experienceTimeZone,
	comment,
	status,
	statusLabel,
	children,
	wrapper,
	bookingId,
}: IBookingDetails) => {
	const { t } = useTranslation(["common", "ui"]);

	const [nbTicketsKids, setNbTicketsKids] = useState<number>(0);

	useEffect(() => {
		let ticketsTempValue = 0;
		tickets?.kids?.forEach(v => (ticketsTempValue += v.quantity));
		setNbTicketsKids(ticketsTempValue);
	}, [tickets]);

	return (
		<Wrapper>
			<Col>
				<ImgContent mainPhotoUrl={mainPhoto || CoverImg} />
			</Col>

			<Col>
				<DescWrapper {...wrapper}>
					<Box display="flex">
						{firstNameLocalhost && localhostLink && (
							<StyledLink to={localhostLink} color={colors.mainFontColor} target="_blank">
								<AvatarWrapper>
									<StyledAvatar alt={`Avatar Local Host - ${firstNameLocalhost}`} src={avatarUrlLocalhost} />

									<Text>{firstNameLocalhost}</Text>
								</AvatarWrapper>
							</StyledLink>
						)}

						{!!link && (
							<StyledLink to={link} color={colors.mainFontColor} target="_blank">
								<StyledTitle>{title}</StyledTitle>
							</StyledLink>
						)}
					</Box>

					<StyledDivider />

					{statusLabel && (
						<Item>
							<Col>
								<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STATUS")}</Text>
							</Col>

							<Col>
								<TextBold>{statusLabel}</TextBold>
							</Col>
						</Item>
					)}

					{firstNameLocalhost && (
						<Item>
							<Col>
								<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.LOCALHOST_NAME")}</Text>
							</Col>

							<Col>
								<TextBold>
									{firstNameLocalhost}{" "}
									{phoneNumberLocalhost && (
										<TextBold>
											(
											<StyledExternalLink color="inherit" href={`tel:${phoneNumberLocalhost}`}>
												{phoneNumberLocalhost}
											</StyledExternalLink>
											)
										</TextBold>
									)}
								</TextBold>
							</Col>
						</Item>
					)}

					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STARTING_DATE")}</Text>
						</Col>

						<Col>
							<TextBold>{parseISOWrapper(startDate, DATE_FORMATS.DATETIME_FORMAT3, true)}</TextBold>

							{startDate && experienceTime && (
								<Box display="inline-flex" position="relative" top="4px" gridGap="10px" marginLeft="10px">
									<LinkGoogleCalendar
										title={title.replace(/\s/g, "+")}
										location={startLocation}
										startDate={parseISOWrapper(
											startDate,
											DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
										)}
										endDate={parseISOWrapper(
											new Date(new Date(startDate).getTime() + experienceTime * oneHourInMinutes * 1000).toISOString(),
											DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
										)}
										timeZone={experienceTimeZone || ""}
										description={t("DASHBOARD_BOOKING_DETAILS_PREVIEW.CALENDAR_EVENT.DESCRIPTION_LINK")}
									/>

									<LinkDownloadFileICS
										title={title}
										location={startLocation}
										startDate={parseISOWrapper(
											startDate,
											DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
										)}
										endDate={parseISOWrapper(
											new Date(new Date(startDate).getTime() + experienceTime * oneHourInMinutes * 1000).toISOString(),
											DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
										)}
										timeZone={experienceTimeZone || ""}
										bookingId={bookingId || ""}
										description={t("DASHBOARD_BOOKING_DETAILS_PREVIEW.CALENDAR_EVENT.DESCRIPTION")}
									/>
								</Box>
							)}
						</Col>
					</Item>

					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.EXPERIENCE_TIME")}</Text>
						</Col>

						<Col>
							<TextBold>{experienceTime ? minutesToHM(experienceTime) : 0}</TextBold>
						</Col>
					</Item>

					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STARTING_LOCATION")}</Text>
						</Col>

						<Col>
							<TextBold>
								<LinkGoogleMaps address={startLocation} />
							</TextBold>
						</Col>
					</Item>

					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.TICKETS")}</Text>
						</Col>

						<Col>
							<TextBold>{(tickets?.adults || 0) + nbTicketsKids}</TextBold>
						</Col>
					</Item>

					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.LANGUAGE")}</Text>
						</Col>

						<Col>
							<TextBold>{language}</TextBold>
						</Col>
					</Item>

					{bookingId && (
						<Item>
							<Col>
								<Text>{t("MY_BOOKINGS.TICKET_NUMBER")}</Text>
							</Col>

							<Col>
								<TextBold>
									<ElemClipboard splitPart={15} elem={bookingId} />
								</TextBold>
							</Col>
						</Item>
					)}

					{!!cost && (
						<Item>
							<Col>
								<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.COST")}</Text>
							</Col>

							<Col>
								<TextBold>{cost}</TextBold>
							</Col>
						</Item>
					)}

					{comment && (
						<Item withMarginTop>
							<Col>
								<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.GUEST_COMMENT")} </Text>
								<br />

								<TextBold>{comment}</TextBold>
							</Col>
						</Item>
					)}

					{children}
				</DescWrapper>
			</Col>
		</Wrapper>
	);
};

interface IDashboardSeeDetailsTraveler extends IBookingDetails {
	id?: string;
	showDialog: boolean;
	handleClose: () => void;
	reload?: (time: number) => void;
	downloadTicketButton?: boolean;
	rateBookingButton?: boolean;
	payButton?: boolean;
	footerComponent?: ReactNode;
}

const DashboardSeeDetailsTraveler = ({
	id,
	handleClose,
	showDialog,
	reload,
	downloadTicketButton,
	rateBookingButton,
	payButton,
	footerComponent,
	...rest
}: IDashboardSeeDetailsTraveler) => {
	const { t } = useTranslation();

	const canCancelBooking =
		!!id &&
		!!rest?.status &&
		[EBookingStatus.NEW, EBookingStatus.PAID, EBookingStatus.PAYMENT_FAILED].includes(rest.status);

	const canDownloadTicket =
		downloadTicketButton &&
		!!id &&
		!!rest?.status &&
		[EBookingStatus.PAID, EBookingStatus.PERFORMED].includes(rest.status);

	const canRateBooking = rateBookingButton && !!id && rest?.status === EBookingStatus.PERFORMED;

	const canPayForBooking =
		payButton && !!id && !!rest?.status && [EBookingStatus.NEW, EBookingStatus.PAYMENT_FAILED].includes(rest.status);

	return (
		<>
			<StyledDialog decorator="withoutLogo" showDialog={showDialog} handleClose={handleClose}>
				<BookingDetails {...rest} bookingId={id}>
					{canPayForBooking && (
						<Box marginTop="25px">
							<StyledLink to={fillRoute(paths.PAYMENT, { bid: id })} $withoutHoverUnderline>
								<Button variant="contained" color="primary">
									{t("MY_BOOKINGS.PAY_FOR_BOOKING")}
								</Button>
							</StyledLink>
						</Box>
					)}

					{canRateBooking && (
						<Box marginTop="25px">
							<RateBookingButton bookingId={id} />
						</Box>
					)}

					{canDownloadTicket && (
						<Box marginTop="25px">
							<DownloadTicketButton bookingId={id} outlined={rest?.status === EBookingStatus.PERFORMED} />
						</Box>
					)}

					{canCancelBooking && (
						<Box marginTop="25px">
							<CancelBookingButton id={id} handleClose={handleClose} reload={reload} />
						</Box>
					)}
				</BookingDetails>

				{footerComponent}
			</StyledDialog>
		</>
	);
};

export default DashboardSeeDetailsTraveler;
