import { TDate } from "data/dates/types";
import { ECurrency } from "data/financials/types";
import { IId } from "data/types";
import { ILocalhostDetails } from "data/users/types";

import { ISuperbookingIId } from "../bookings/types";

export enum ETransaction {
	AFFILIATE_CODE = "AFFILIATE_CODE",
	GIFT_CARD = "GIFT_CARD",
	LOCALBINI_EARNINGS = "LOCALBINI_EARNINGS",
	LOCALHOST_EARNINGS = "LOCALHOST_EARNINGS",
	MANUAL_ADJUSTMENT = "MANUAL_ADJUSTMENT",
	PARTNER_COMMISSION = "PARTNER_COMMISSION",
	PAYMENT = "PAYMENT",
	PRICING_STRATEGY = "PRICING_STRATEGY",
	PRICING_TABLES = "PRICING_TABLES",
	PROMO_CODE = "PROMO_CODE",
	PROMO_CODE_PROFIT = "PROMO_CODE_PROFIT",
	REFUND = "REFUND",
	REIMBURSEMENT = "REIMBURSEMENT",
	SUBDOMAIN_COMMISSION = "SUBDOMAIN_COMMISSION",
	VAT = "VAT",
}

export enum EFinancial {
	INCOME = "INCOME",
	OUTCOME = "OUTCOME",
}

export enum EStatus {
	NEW = "NEW",
	REALIZED = "REALIZED",
	FAILED = "FAILED",
}

export enum ERelatedEntity {
	LOCALBINI = "LOCALBINI",
	TRAVELER = "TRAVELER",
	LOCALHOST = "LOCALHOST",
	USER = "USER",
	OTA = "OTA",
	TAX = "TAX",
}

export enum ELocalhostFinancialStatementStatus {
	"APPROVED" = "APPROVED",
	"NEW" = "NEW",
	"PAID" = "PAID",
	"PAYMENT_FAILED" = "PAYMENT_FAILED",
	"UNDER_REVIEW" = "UNDER_REVIEW",
	"APPROVED__NO_BANKING_DETAILS" = "APPROVED__NO_BANKING_DETAILS",
}

export type TFinancial = keyof typeof EFinancial;
export type TStatus = keyof typeof EStatus;
export type TCurrency = keyof typeof ECurrency;
export type TTransaction = keyof typeof ETransaction;
export type TRelatedEntity = keyof typeof ERelatedEntity;

export interface IFinancial {
	amount: number;
	booking_id: string;
	superbooking_id: string;
	currency: ECurrency;
	related_entity: ERelatedEntity;
	status?: EStatus;
	type: EFinancial;
	subtype: ETransaction;
	comment: string | null;
	uid: string;
	code_usage_id?: string;
}

export type TFinancialIId = IFinancial & IId;

export interface IBookingStatistics {
	total: number;
	performed: number;
	refunded: number;
}

export interface ILocalhostFinancialStatement {
	approved_by: string | null;
	approved_on: TDate | null;
	status: ELocalhostFinancialStatementStatus;
	year: number;
	month: number;
	currency: ECurrency;
	earnings: number;
	reimbursement: number;
	banking_details_filled: boolean;
	localhost_id: string;
	localhost_details: ILocalhostDetails;
	booking_statistics: IBookingStatistics;
	superbookings: Record<string, ELocalhostFinancialStatementStatus>;
}

export type TLocalhostFinancialStatementIId = ILocalhostFinancialStatement & IId;

export interface IAggregationBySuperbooking {
	created_on: string;
	currency: ECurrency;
	gross_revenue: number;
	localbini_earnings: number;
	localhost_earnings: number;
	localhost_id: string;
	localhost_reimbursement: number;
	modified_on: string;
	net_revenue: number;
	partner_commission: number;
	superbooking_details: ISuperbookingIId;
	superbooking_id: string;
	traveler_payment: number;
	vat: number;
}

export interface IBankTransferFileForLocalhostFinancialStatementsParams {
	currency: ECurrency;
	month?: number;
	year?: number;
	status?: string[];
}

export interface ILocalhostFinancialStatementStatisticsSum {
	count: number;
	sum: {
		[key in ECurrency]?: number;
	};
}

export interface ILocalhostFinancialStatementStatistics {
	total: ILocalhostFinancialStatementStatisticsSum;
	by_status: {
		[key in ELocalhostFinancialStatementStatus]?: ILocalhostFinancialStatementStatisticsSum;
	};
}
